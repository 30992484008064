import { render, staticRenderFns } from "./ListTiendasOficiales.pug?vue&type=template&id=56d675df&scoped=true&lang=pug&"
import script from "./ListTiendasOficiales.js?vue&type=script&lang=js&"
export * from "./ListTiendasOficiales.js?vue&type=script&lang=js&"
import style0 from "./ListTiendasOficiales.vue?vue&type=style&index=0&id=56d675df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d675df",
  null
  
)

export default component.exports